<template>
  <v-dialog
    v-model="internalShowing"
    max-width="800px"
  >
    <v-card>
      <v-card-text>
        <v-simple-table
          class="pt-4"
          dense
          fixed-header
        >
          <template #default>
            <thead>
              <tr>
                <th>Técnico</th>
                <th>Inicio</th>
                <th>Tiempo</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(linea, index) in lineasFormatted"
                :key="index"
              >
                <td>{{ linea.empleado.nombre_y_apellido }}</td>
                <td>{{ linea.tiempo_trabajado.finicio|shortDateTime }}</td>
                <td>
                  <v-icon
                    v-if="linea.tiempo_trabajado.es_desplazamiento"
                    left
                    small
                  >
                    {{ $vuetify.icons.values.desplazamiento }}
                  </v-icon>
                  <v-icon
                    v-else
                    left
                    small
                  >
                    {{ $vuetify.icons.values.manoDeObra }}
                  </v-icon>
                  {{ linea.tiempoTrabajado|humanizeDuration }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCerrar"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    lineas: {
      type: Array,
      default: () => [],
    },
    mostrarTiemposDesplazamiento: {
      type: Boolean,
    },
  },
  computed: {
    lineasFormatted () {
      const lineas = []
      for (let linea of this.lineas) {
        if (
          !linea.tiempo_trabajado.es_desplazamiento ||
          linea.tiempo_trabajado.manodeobrafacturable ||
          this.mostrarTiemposDesplazamiento
        ) {
          lineas.push(linea)
        }
      }
      return lineas
    }
  },
  methods: {
    clickCerrar () {
      this.internalShowing = false
    },
  },
}
</script>
